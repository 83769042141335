/* eslint-disable array-callback-return */
import { createSlice, configureStore } from '@reduxjs/toolkit'


const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    cart: localStorage.getItem('cartTotem') === null ? {
      Country: "BR",
      city: 'Rio de Janeiro',
      state: 'RJ',
      agent: "AWFE57Q", // Agente 'Totem'
      discount: "0",
      foreign: 2,
      language: 1,
      resellerReference: "",
      source: "totem",
      totalCart: "0",
      dados: [],
      payments: [],
      cupom:{
        type: 0
      }
    }
      :
      JSON.parse(localStorage.getItem('cartTotem') || '{}')
  },
  reducers: {
    addItemCart: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cart.dados = [...state.cart.dados, action.payload]
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },
    
    removeItemCart: (state: any, action) => {
      // state.cart-= 1
      state.cart.dados = state.cart.dados.filter((item: any) => item.productCode !== action.payload.productCode);
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeTotalCart: (state: any, action) => {
      // state.cart-= 1
      state.cart.dados = [];
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateDadosCart: (state: any, action) => {
      state.cart.dados = [...action.payload]
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCupomCart: (state: any, action) => {
      // state.cart-= 1
      state.cart.cupom = action.payload;
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    }
  }
})

export const { addItemCart, removeItemCart, removeTotalCart, updateDadosCart, updateCupomCart } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer
})

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {    
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("cartTotem", serialisedState);
    
  } catch (e) {
    console.warn(e);
  }
}

function updateTotalCart(state: any){
  let totalCart = 0;
    
  try {
    let cartTemp = JSON.stringify(state);
    let cartObj = JSON.parse(cartTemp);
    console.log(cartObj);
    let priceProduct = 0;
    cartObj.dados.map((cItem: any, index: any) => {      
        priceProduct = (
          (Number(cItem.adults) * Number(cItem.priceAdults))
          + (Number(cItem.childs) * Number(cItem.priceChilds))
          + (Number(cItem.infants) * Number(cItem.priceInfants))
          + (Number(cItem.elders) * Number(cItem.priceElders))
          + (Number(cItem.student) * Number(cItem.priceStudent))
          + (Number(cItem.priceGlobalPeople))
        );
      totalCart = Number(totalCart ) + (priceProduct );      
      
    });
    if(cartObj.cupom.type === 2){
      totalCart = Number(totalCart ) - (cartObj.cupom.value);
    }else if(cartObj.cupom.type === 4){
      totalCart = Number(totalCart ) - ((totalCart * (cartObj.cupom.value / 100)));
    }
    return totalCart;

    
  } catch (e) {
    console.warn(e);
  }

}



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;