import React from 'react';
import './PreLoader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PreLoader() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{ marginTop: '60px' }}>
          <img src={process.env.REACT_APP_SERVER_LOGO} alt="Bondinho" className="float-left" />
        </p>

        <FontAwesomeIcon
          className="App-logo"
          size="6x"
          icon={['fal', 'spinner-third']}
        />
      </header>
    </div>
  );
}

export default PreLoader;