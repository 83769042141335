import pt from './default.json';
import en from './default.en.json';
import es from './default.es.json';

//Pages
import optionsPt from '../../pages/Options/locales/default.json';
import optionsEn from '../../pages/Options/locales/default.en.json';
import optionsEs from '../../pages/Options/locales/default.es.json';

import productPt from '../../pages/Products/locales/default.json';
import productEn from '../../pages/Products/locales/default.en.json';
import productEs from '../../pages/Products/locales/default.es.json';

import personalDataPt from '../../pages/PersonalData/locales/default.json';
import personalDataEn from '../../pages/PersonalData/locales/default.en.json';
import personalDataEs from '../../pages/PersonalData/locales/default.es.json';

import cartPt from '../../pages/Cart/locales/default.json';
import cartEn from '../../pages/Cart/locales/default.en.json';
import cartEs from '../../pages/Cart/locales/default.es.json';

import modalPt from '../../components/Modal/ModalAlert/locales/default.json';
import modalEn from '../../components/Modal/ModalAlert/locales/default.en.json';
import modalEs from '../../components/Modal/ModalAlert/locales/default.es.json';

import PrivacyTermsPt from '../../components/PrivacyTerms/locales/default.json';
import PrivacyTermsEn from '../../components/PrivacyTerms/locales/default.en.json';
import PrivacyTermsEs from '../../components/PrivacyTerms/locales/default.es.json';

import slowBuyPt from '../../pages/SingleProduct/locales/default.json';
import slowBuyEn from '../../pages/SingleProduct/locales/default.en.json';
import slowBuyEs from '../../pages/SingleProduct/locales/default.es.json';

import redeemBuyPt from '../../pages/Summary/components/RedeemBuy/locales/default.json';
import redeemBuyEn from '../../pages/Summary/components/RedeemBuy/locales/default.en.json';
import redeemBuyEs from '../../pages/Summary/components/RedeemBuy/locales/default.es.json';

import summaryBuyPt from '../../pages/Summary/components/SummaryBuy/locales/default.json';
import summaryBuyEn from '../../pages/Summary/components/SummaryBuy/locales/default.en.json';
import summaryBuyEs from '../../pages/Summary/components/SummaryBuy/locales/default.es.json';

import checkoutPt from '../../pages/Checkout/locales/default.json';
import checkoutEn from '../../pages/Checkout/locales/default.en.json';
import checkoutEs from '../../pages/Checkout/locales/default.es.json';

import printingPt from '../../pages/PrintingTickets/locales/default.json';
import printingEn from '../../pages/PrintingTickets/locales/default.en.json';
import printingEs from '../../pages/PrintingTickets/locales/default.es.json';

//Components

pt['pt-BR'].options = optionsPt['pt-BR'];
en['en'].options = optionsEn['en'];
es['es'].options = optionsEs['es'];

pt['pt-BR'].product = productPt['pt-BR'];
en['en'].product = productEn['en'];
es['es'].product = productEs['es'];

pt['pt-BR'].personalData = personalDataPt['pt-BR'];
en['en'].personalData = personalDataEn['en'];
es['es'].personalData = personalDataEs['es'];

pt['pt-BR'].cart = cartPt['pt-BR'];
en['en'].cart = cartEn['en'];
es['es'].cart = cartEs['es'];

// pt['pt-BR'].tour = tourPt['pt-BR'];
// en['en'].tour = tourEn['en'];
// es['es'].tour = tourEs['es'];

pt['pt-BR'].modal = modalPt['pt-BR'];
en['en'].modal = modalEn['en'];
es['es'].modal = modalEs['es'];

pt['pt-BR'].privacyTerms = PrivacyTermsPt['pt-BR'];
en['en'].privacyTerms = PrivacyTermsEn['en'];
es['es'].privacyTerms = PrivacyTermsEs['es'];

pt['pt-BR'].slowBuy = slowBuyPt['pt-BR'];
en['en'].slowBuy = slowBuyEn['en'];
es['es'].slowBuy = slowBuyEs['es'];

pt['pt-BR'].redeemBuy = redeemBuyPt['pt-BR'];
en['en'].redeemBuy = redeemBuyEn['en'];
es['es'].redeemBuy = redeemBuyEs['es'];

pt['pt-BR'].summaryBuy = summaryBuyPt['pt-BR'];
en['en'].summaryBuy = summaryBuyEn['en'];
es['es'].summaryBuy = summaryBuyEs['es'];

pt['pt-BR'].checkout = checkoutPt['pt-BR'];
en['en'].checkout = checkoutEn['en'];
es['es'].checkout = checkoutEs['es'];

pt['pt-BR'].printing = printingPt['pt-BR'];
en['en'].printing = printingEn['en'];
es['es'].printing = printingEs['es'];

export {
  pt,
  en,
  es,
};