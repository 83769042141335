import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import store from './store/cart';
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';


import './assets/sass/global.scss'

import Routes from './routes';


function App() {
  library.add(fas, fal, fad, fab);

  return (
    <div className="App">
      <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </I18nextProvider>
      </Provider>
    </div>
  );
}

export default App;